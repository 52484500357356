import React from 'react';
import { alpha, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Main from 'layouts/Main';
import Container from 'components/Container';
import Divider from '@mui/material/Divider';

import {
  Hero,
  Services,
  QuickStart,
  Features,
  Benefits,
  GetStarted,
} from './components';


const IndexView = () => {
  const { palette:{
    background:{paper},
    alternate:{main:altMain}
  },spacing } = useTheme()

  return (
    <Box sx={{ overflowX: 'hidden' }}>
      <Main
        bgcolor={paper}
        title={''}>
        <Hero />
        <Container>
          <Services />
        </Container>
        <Box
          sx={{backgroundImage: `linear-gradient(to bottom, ${alpha( paper, 0,)}, ${alpha(altMain, 1)} 100%)`,
            backgroundRepeat: 'repeat-x',
            position: 'relative',
          }}
        >
          <Box>
          <Divider/>
          <Container maxWidth={800} >
            <QuickStart />
          </Container>
          <Divider/>
          </Box>

          <Container>
            <Features />
          </Container>
          <Container>
            <Benefits />
          </Container>
          <Box
            component={'svg'}
            preserveAspectRatio="none"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 1920 100.1"
            sx={{
              width: '100%',
              marginBottom: spacing(-1),
            }}
          >
            <path
              fill={paper}
              d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z"
            ></path>
          </Box>
        </Box>
        <Container>
          <GetStarted />
        </Container>
      </Main>
    </Box>
  );
};

export default IndexView;
